.button {
  border: none;
  border-radius: 10px;
  font-weight: bold;
}

.primary:hover {
  border: none;
}

.btn-social {
  background-color: #29b9dd;
  border: 0;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  text-align: left;
  width: 100%;
}

.btn-social-onboarding {
  background-color: white;
  border-radius: 10px;
  color: black;
  font-weight: normal;
  text-align: left;
  width: 100%;
}

.carscore-btn {
  background-color: #1f1d4c;
}

.check-box {
  background-color: #98e885;
  height: 15px;
  width: 15px;
  border: none;
}
