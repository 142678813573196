.field {
	border-radius: 0.5rem;
	color: #000;
}

.groupTextRound {
	background-color: #216c99;
	border: #216c99;
	border-radius: 10px 0px 0px 10px;
	color: #fff;
}

.selectField {
	border-radius: 10px;
}
